import { render, staticRenderFns } from "./rankingList.vue?vue&type=template&id=83e00d94&scoped=true&"
import script from "./rankingList.vue?vue&type=script&lang=js&"
export * from "./rankingList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83e00d94",
  null
  
)

/* custom blocks */
import block0 from "./rankingList.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports