<i18n>
{
  "en": {
    "browser_title": "Rankings",
    "heading": "Rankings",
    "tab_weekly": "Weekly",
    "tab_monthly": "Monthly",
    "tab_yearly": "Yearly",
    "tab_actress": "Actresses"
  },
  "ja": {
    "browser_title": "ランキング",
    "heading": "人気ランキング",
    "tab_weekly": "週間ランキング",
    "tab_monthly": "月間ランキング",
    "tab_yearly": "年間ランキング",
    "tab_actress": "AV女優ランキング"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />
  <div class="contents">
    <div class="heading">
      <h1>{{ $t('heading') }}</h1>
    </div>
    <vue-tabs class="tab-scroll" id="ranking--tab-rank-list" v-model="activeTab" @tab-change="tabChangeHandler">
      <v-tab :title="$t('tab_weekly')">
        <ranking-list list-type="weekly" :list-name="$t('tab_weekly')" :list-size="20" />
      </v-tab>
      <v-tab :title="$t('tab_monthly')">
        <ranking-list list-type="monthly" :list-name="$t('tab_monthly')" :list-size="20" />
      </v-tab>
      <v-tab :title="$t('tab_yearly')">
        <ranking-list list-type="yearly" :list-name="$t('tab_yearly')" :list-size="20" />
      </v-tab>
      <v-tab :title="$t('tab_actress')">
        <ranking-list list-type="actress" :list-name="$t('tab_actress')" :list-size="20" />
      </v-tab>
    </vue-tabs>
  </div>
</main>
</template>

<script>
/* eslint max-len: 0 */

import { VueTabs, VTab } from 'vue-nav-tabs';
import vueHeadful from 'vue-headful';
import EventBus from '@/assets/js/utils/EventBus';
import APP_CONFIG from '@/appConfig';
import RankingList from '@/components/ranking/rankingList.vue';

export default {
  components: {
    VueTabs,
    VTab,
    'vue-headful': vueHeadful,
    'ranking-list': RankingList,
  },
  data() {
    return {
      dynamicTitle: '',
      activeTab: (this.$route.hash) ? decodeURIComponent(this.$route.hash).substr(1) : this.$t('tab_weekly'),
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    if (this.$route.hash) {
      this.activeTab = decodeURIComponent(this.$route.hash).substr(1); // remove the hash character
    }

    // one-time emit to get the contents of the 1st tab
    EventBus.$emit('actress:tab:active', this.activeTab);
  },
  methods: {
    tabChangeHandler(tabIndex, newTab) {
      // using tab-change event available in vue-nav-tabs: http://vuejs.creative-tim.com/vue-tabs/#/
      this.$analytics.trackEvent('Ranking Page', newTab.title);
      EventBus.$emit('ranking:tab:active', newTab.title);

      // doing a sneaky thing here and using the history API to add a hash anchor instead of using
      // $router.replace() since that seems to trigger a route change, which is what we don't want.
      // btw, using replaceState instead of pushState so that the tab changes don't annoyingly get
      // saved in the browser history
      window.history.replaceState({}, null, `${this.$route.path}#${encodeURIComponent(newTab.title)}`);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_ranking.scss';
</style>
