<i18n>
{
  "en": {
    "movie_count": "# Movies",
    "latest_movie": "Latest Movie"
  },
  "ja": {
    "movie_count": "出演作品数",
    "latest_movie": "最新作"
  }
}
</i18n>

<template>
<div>
  <!-- dropdown for year select (year ranking only) -->
  <div v-if="listType == 'yearly'">
    <select v-model="yearSelected" @change="updateYearlyList()" class="year-dropdown">
      <option v-for="year in yearRange" :key="year" :value="year">{{ year }}</option>
    </select>
  </div>

  <!-- weekly/monthly/yearly list -->
  <div v-if="listType !== 'actress'" class="ranking ranking-movie">
    <div class="list list--compact">
      <div class="flex-grid">
        <!-- movie item -->
        <movie-thumbnail
          v-for="(movie, index) in rankingList"
          :key="movie.MovieID"
          :movie="movie"
          :index="index"
          theme="ranking" />
        <!-- /movie item -->
      </div>
    </div><!-- /.list -->
  </div>

  <!-- actress list -->
  <div v-if="listType === 'actress'" class="ranking ranking-actress">
    <div class="list list--compact">
      <div class="flex-grid">

        <div class="grid-item" v-for="(movie, index) in rankingList" :key="movie.MovieID">
          <div class="entry">
            <div class="entry-ranking">
              <div v-if="index == 0" class="ranking-number ranking-number--gold">{{ index + 1 }}</div>
              <div v-if="index == 1" class="ranking-number ranking-number--silver">{{ index + 1 }}</div>
              <div v-if="index == 2" class="ranking-number ranking-number--bronze">{{ index + 1 }}</div>
              <div v-if="index > 2" class="ranking-number">{{ index + 1 }}</div>
            </div>
             <router-link :to="`/search/?a=${movie.ActorID[0]}`" class="entry-media">
              <div class="media-thum">
                <img class="media-thum-image" :src="`/assets/sample/${movie.MovieID}/thum_b.jpg`" @error="imgPlaceholder" loading="lazy" alt="fpo" v-sfw>
              </div>
            </router-link>
            <div class="entry-meta">
              <div class="meta-title"><router-link :to="`/search/?a=${movie.ActorID[0]}`">{{ (locale == 'ja') ? movie.ActressesList[movie.ActorID[0]].NameJa : movie.ActressesList[movie.ActorID[0]].NameEn }}</router-link></div>
              <!-- TODO <div class="meta-data">{{ actress_alt_names }}</div> -->
              <div class="meta-data">{{ $t('movie_count') }}: {{ movie.MovieCount }}</div>
              <div class="meta-data" v-if="locale == 'ja'">{{ $t('latest_movie') }}:<em><router-link :to="`/movies/${movie.MovieID}/`">{{ movie.Title }}</router-link></em></div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /.list -->
  </div>
</div>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-shadow: 0 */
import range from 'lodash/range';
import EventBus from '@/assets/js/utils/EventBus';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import movieThumbnail from '@/components/_common/movieThumbnail.vue';

export default {
  props: {
    listSize: {
      type: Number,
      default: 10,
    },
    listType: {
      type: String,
      default: 'weekly',
    },
    listName: {
      type: String,
    },
  },
  components: {
    'movie-thumbnail': movieThumbnail,
  },
  data() {
    return {
      rankingList: [],
      yearSelected: new Date().getFullYear(),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  created() {
    // non-reactive consts
    this.currentYear = new Date().getFullYear();
    this.yearRange = range(2010, new Date().getFullYear() + 1).reverse();

    // set listener for emit event from the parent tab component
    EventBus.$on('ranking:tab:active', async (title) => {
      // get list if the title matches the 1st character and hasn't been fetched yet
      if (this.listName === title && this.rankingList.length === 0) {
        this.getRankings(this.listType, this.userTypeID);
      }
    });
  },
  methods: {
    getRankings(listType, userTypeID) {
      const bfAPI = new BifrostAPI();

      switch (listType) {
        case 'weekly':
          bfAPI.getMovieListWeekly(0, this.listSize).then((result) => {
            const rankingList = MovieHelper.expandDetailsList(
              result.Rows,
              this.locale,
              userTypeID,
            );
            this.rankingList = MovieHelper.filterExcludedMovieList(
              rankingList,
              userTypeID,
            );
          });
          break;
        case 'monthly':
          bfAPI.getMovieListMonthly(0, this.listSize).then((result) => {
            const rankingList = MovieHelper.expandDetailsList(
              result.Rows,
              this.locale,
              userTypeID,
            );
            this.rankingList = MovieHelper.filterExcludedMovieList(
              rankingList,
              userTypeID,
            );
          });
          break;
        case 'yearly':
          bfAPI
            .getMovieListYearly(
              0,
              this.listSize,
              parseInt(this.yearSelected, 10),
            )
            .then((result) => {
              const rankingList = MovieHelper.expandDetailsList(
                result.Rows,
                this.locale,
                userTypeID,
              );
              this.rankingList = MovieHelper.filterExcludedMovieList(
                rankingList,
                userTypeID,
              );
            });
          break;
        case 'actress':
          // based on actresses from the monthly movie ranking
          bfAPI.getMovieListMonthly(0, this.listSize).then((result) => {
            const movieList = result;
            for (let i = 0; i < movieList.Rows.length; i += 1) {
              movieList.Rows[i].MovieCount = 0;

              // get actress movies count
              // capturing i in a closure since we're passing it to a promise (otherwise i will
              // always be the same value without the closure)
              ((i) => {
                bfAPI
                  .getMovieListActress(0, 500, movieList.Rows[i].ActorID[0])
                  .then((actressMovies) => {
                    movieList.Rows[i].MovieCount = actressMovies.TotalRows;
                  });
              })(i);
            }
            const rankingList = MovieHelper.expandDetailsList(
              result.Rows,
              this.locale,
              userTypeID,
            );
            this.rankingList = MovieHelper.filterExcludedMovieList(
              rankingList,
              userTypeID,
            );
          });
          break;
        default:
          bfAPI.getMovieListWeekly(0, this.listSize).then((result) => {
            const rankingList = MovieHelper.expandDetailsList(
              result.Rows,
              this.locale,
              userTypeID,
            );
            this.rankingList = MovieHelper.filterExcludedMovieList(
              rankingList,
              userTypeID,
            );
          });
          break;
      }
    },
    updateYearlyList() {
      const bfAPI = new BifrostAPI();
      bfAPI
        .getMovieListYearly(0, this.listSize, parseInt(this.yearSelected, 10))
        .then((result) => {
          const rankingList = MovieHelper.expandDetailsList(
            result.Rows,
            this.locale,
            this.userTypeID,
          );
          this.rankingList = MovieHelper.filterExcludedMovieList(
            rankingList,
            this.userTypeID,
          );
        });
    },
    imgPlaceholder(e) {
      e.target.src = '/img/common/actress_placeholder.png';
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getRankings(this.listType, newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
